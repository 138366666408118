import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { setContext } from '@apollo/client/link/context';

import packageJson from '../../package.json';
import { sha256 } from 'crypto-hash';

const gatewayGraphQLURl: string =
  'https://current--brickverse.apollographos.net/graphql';

const theFetch = async (url, options) => {
  try {
    const res = await fetch(url.toString(), {
      method: options?.method || 'POST',
      headers: {
        'content-type': 'application/json',
        'x-graphql-client-name': 'brickverse-web',
        'x-graphql-client-version': packageJson.version,
        ...options.headers,
      },
      body: options?.body,
    });

    return res;
  } finally {
    console.log('finally');
  }
};

const httpLink = createHttpLink({
  uri: gatewayGraphQLURl,
  fetch: theFetch,
});

const queryLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('_authing_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

const httpLinkForNormalOperations = ApolloLink.from([queryLink, httpLink]);

export const client = new ApolloClient({
  link: concat(authLink, httpLinkForNormalOperations),
  cache: new InMemoryCache(),
});

export const noCacheClient = new ApolloClient({
  link: concat(authLink, httpLink),
  cache: new InMemoryCache(),
});
